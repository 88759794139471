/* eslint-env worker */

function Pixel (x, y) {
  this.x = x
  this.y = y
  this.hue = Math.floor(Math.random() * 360)
  var direction = Math.random() > 0.5 ? -1 : 1
  this.velocity = (Math.random() * 30 + 20) * 0.01 * direction
}

Pixel.prototype.update = function () {
  this.hue += this.velocity
}

Pixel.prototype.render = function (ctx) {
  var hue = Math.round(this.hue)
  ctx.fillStyle = 'hsl(' + hue + ', 100%, 50% )'
  ctx.fillRect(this.x, this.y, 2, 2)
}

var pixels = [
  new Pixel(0, 0),
  new Pixel(0, 1),
  new Pixel(1, 0),
  new Pixel(1, 1)
]

this.onmessage = async function (e) {
  switch (e.data.command) {
    case 'add-canvas':
      let canvas = e.data.canvas
      this.actx = canvas.getContext('2d', {alpha: false})

      this.animate()
      break
    case 'stop':
      cancelAnimationFrame(this.rafId)
      break
  }
}

this.animate = () => {
  pixels.forEach(function (pixel) {
    pixel.update()
    pixel.render(this.actx)
  })

  this.rafId = requestAnimationFrame(this.animate)
}
